<template>
    <section class="wrapper">
        <p class="title-log">{{title[lang]}}</p>
        <div class="line"></div>
        <div class="rhythm">
            <div v-for="(item, key) in log[lang]" :key="key">
                <h3>{{item.version}} ( {{item.date}} )</h3>
                <div v-for="(v, k) in item.content" :key="k">
                    <p v-if="typeof v == 'string'">{{v}}</p>
                    <ul v-else>
                        <li v-for="(a, b) in v" :key="b">{{a}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'VersionLog',
        components: {},
        props: {},
        data() {
            return {
                lang: this.$route.params.lang || 'en',

                title: {
                    en: 'Version Log',
                    zh_CN: '版本日志',
                    zh_TW: '版本日誌',
                },

                log: {

                }
            }
        },
        computed: {},
        methods: {},
        mounted() {

        },
    }
</script>

<style scoped lang="less">
    .title-log {
        font-size: 24px;
        line-height: 1.3;
        text-align: center;
        padding-top: 30px;
        color: #222;
    }

    .line {
        width: 100%;
        height: 16px;
        margin: 1em 0 2em 0;
        background-size: contain;
        background-position: center bottom;
        background-repeat: no-repeat;
        background-image: url(../../assets/line.png);
    }

    .rhythm {
        width: 90%;
        overflow: hidden;
        display: block;
        margin: 0 auto;
        color: #555;
        p {
            line-height: 1.6em;
            margin-bottom: 1em;
        }

        h3 {
            margin-top: 1.33em;
            margin-bottom: 0.44em;
            font-family: "Helvetica Neue", Helvetica, Arial, "Rhythm Heiti", sans-serif;
            line-height: 1.6em;
            font-size: 1.4rem;
            font-weight: normal;
            color: #222;
        }

        ul {
            list-style-type: disc;
            padding-left: 2em;
            margin-bottom: 1em;

            li {
                line-height: 1.6em;
            }
        }
    }

</style>
